import { baseApi } from '@/lib';

import { ChildrenFromTenantResponse, ReportsResponse } from '../types';

type ChildrenFromTenantRequestDTO = string;

const enchancedApi = baseApi.enhanceEndpoints({
  addTagTypes: ['Reports', 'Children'],
});

export const reportsApi = enchancedApi.injectEndpoints({
  endpoints: (builder) => ({
    reports: builder.query<ReportsResponse, void>({
      query: () => 'reports',
      providesTags: (res) => {
        return res
          ? [
              ...res.map((report) => ({ type: 'Reports' as const, id: report.reportType })),
              { type: 'Reports', id: 'LIST' },
            ]
          : [{ type: 'Reports', id: 'LIST' }];
      },
    }),
    childrenFromTenant: builder.query<ChildrenFromTenantResponse, ChildrenFromTenantRequestDTO>({
      query: (tenant) => `children/${tenant}`,
      providesTags: (res) => {
        return res
          ? [
              ...res.map((child) => ({ type: 'Reports' as const, id: child.id })),
              { type: 'Reports', id: 'LIST' },
            ]
          : [{ type: 'Reports', id: 'LIST' }];
      },
    }),
  }),
});

export const { useReportsQuery, useChildrenFromTenantQuery } = reportsApi;
