import { Box, FormControl } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';

// eslint-disable-next-line react/display-name
const FilePickerWithRef = React.forwardRef((props: any, ref: any) => {
  const { onDrop, multiple = false, labelId } = props;

  const handleDrop = useCallback(
    (files: Array<File>) => {
      if (multiple) onDrop(files);
      else onDrop(files[0]);
    },
    [multiple, onDrop]
  );

  return (
    <Dropzone ref={ref} onDrop={handleDrop} multiple={multiple} noClick noKeyboard>
      {({ getRootProps, getInputProps }) => {
        return (
          <Box {...getRootProps({ className: 'dropzone' })} style={{ visibility: 'collapse' }}>
            <FormControl>
              <input {...getInputProps()} aria-label="upload" id={labelId || 'file-upload'} />
            </FormControl>
          </Box>
        );
      }}
    </Dropzone>
  );
});

FilePickerWithRef.propTypes = {
  onDrop: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  labelId: PropTypes.string,
  sx: PropTypes.object,
};

export default FilePickerWithRef;
