import { Checkbox } from '@chakra-ui/checkbox';
import React, { useEffect, useState } from 'react';
import { Control, useController } from 'react-hook-form';

import { FormReactSelect } from '@/components/form/FormReactSelect';
import { useDomainsQuery } from '@/features/domains';

type Props = {
  control: Control<any, any>;
  name?: string;
};

export const SchemasPicker = ({ control, name = 'schemas' }: Props) => {
  const { data: domains, isLoading: isLoadingDomains } = useDomainsQuery();
  const { field } = useController({
    name,
    control,
  });

  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    if (selectAll) {
      field.onChange(domains);
    } else {
      field.onChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domains, selectAll]);

  return (
    <>
      <FormReactSelect
        control={control}
        isLoading={isLoadingDomains}
        isDisabled={isLoadingDomains || selectAll}
        name={name}
        options={domains || []}
        placeholder={'Wybierz placówki'}
        getOptionLabel={(o) => o.name}
        getOptionValue={(o) => o.domain}
        isMulti
      />
      <Checkbox
        colorScheme={'purple'}
        checked={selectAll}
        onChange={(e) => setSelectAll(e.target.checked)}
        isDisabled={isLoadingDomains}
      >
        Wszystkie placówki
      </Checkbox>
    </>
  );
};
