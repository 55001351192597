import { FormControl, FormLabel, Switch, Text } from '@chakra-ui/react';
import { Control, useController } from 'react-hook-form';

type Props = {
  control: Control<any>;
  name: string;
  label?: string;
};

const FormSwitch = ({ control, name, label = 'Switch', ...props }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    control,
    name,
  });

  const handleChange = (val: boolean) => {
    field.onChange(val);
  };

  return (
    <FormControl display="flex" alignItems="center">
      <FormLabel htmlFor={name} mb="0">
        {label}
      </FormLabel>
      <Switch
        {...field}
        id={name}
        isChecked={field.value}
        onChange={(e) => handleChange(e.target.checked)}
        {...props}
      />
      {error && <Text color="red.500">{error.message}</Text>}
    </FormControl>
  );
};

export default FormSwitch;
