import { baseApi } from '@/lib';

const enchancedApi = baseApi.enhanceEndpoints({
  addTagTypes: ['BoardPosts'],
});

type FileUploadResponse = {
  id: string;
  fileUrl: string;
  thumbnailUrl: string | null;
  fileStorageUrl: string;
  thumbnailStorageUrl: string | null;
  name: string;
  mimeType: string;
  size: number;
  uploadedAt: Date;
  description: string | null;
  uploadedBy: string;
  type: string;
  dataType: string | null;
};

export const boardPostsApi = enchancedApi.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<FileUploadResponse, FormData>({
      query: (data) => ({
        url: `files`,
        method: 'POST',
        body: data,
      }),
    }),
  }),
});

export const { useUploadFileMutation } = boardPostsApi;
