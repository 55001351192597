import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { FormDatePicker } from '@/components/form/FormDatePicker';
import { FormReactSelect } from '@/components/form/FormReactSelect';
import { FormSection } from '@/components/form/FormSection';
import { useDomainsQuery, Domain } from '@/features/domains';

import { useChildrenFromTenantQuery } from '../../api/reportsApi';
import { Child, ReportFormProps } from '../../types';
import { blobToJsonAsync } from '../../utils/blobToJson';
import { downloadReport } from '../../utils/downloadReport';
import { ReportModalForm } from '../ReportModalForm';

type Form = {
  domain: Domain | null;
  child: Child | null;
  date: Date;
};

const defaultValues = {
  domain: null,
  child: null,
  date: dayjs().toDate(),
};

const scheme = yup.object({
  date: yup.date().max(new Date()).nullable().required('Pole wymagane'),
  domain: yup.object().nullable().required('Pole wymagane'),
  child: yup.object().nullable().required('Pole wymagane'),
});

export const AccountingNoteForm = (props: ReportFormProps) => {
  const { report } = props;
  const {
    formState: { isSubmitting },
    watch,
    handleSubmit,
    control,
    setValue,
  } = useForm<Form>({
    defaultValues,
    resolver: yupResolver(scheme),
  });
  const watchTenant = watch('domain', null);

  const { data: domains, isLoading: isLoadingDomains } = useDomainsQuery();
  const { data: childrenFromTenant, isLoading: isLoadingChildrenFromTenant } =
    useChildrenFromTenantQuery(watchTenant?.domain as string, {
      skip: !watchTenant,
    });

  useEffect(() => {
    setValue('child', null);
  }, [childrenFromTenant, setValue]);

  const handleFormSubmit = async (values: Form) => {
    try {
      await downloadReport(report, {
        date: dayjs(values.date).format('YYYY-MM-DD'),
        domain: values.domain?.domain,
        childId: values.child?.id,
      });
      props.onClose();
    } catch (err: any) {
      const error = await blobToJsonAsync(err.response.data);
      toast.error(error.message);
    }
  };

  return (
    <ReportModalForm
      {...props}
      onSubmit={handleSubmit(handleFormSubmit)}
      isSubmitting={isSubmitting}
      saveButtonLabel={'Pobierz notę'}
    >
      <FormSection title="Placówka">
        <FormReactSelect
          control={control}
          name={'domain'}
          isLoading={isLoadingDomains}
          isDisabled={isLoadingDomains}
          options={domains || []}
          getOptionLabel={(o) => `${o.domain} - ${o.name}`}
          getOptionValue={(o) => o.domain}
          placeholder={'Wybierz placówkę'}
        />
      </FormSection>
      <FormSection title="Dziecko">
        <FormReactSelect
          control={control}
          name={'child'}
          isLoading={isLoadingChildrenFromTenant}
          isDisabled={isLoadingChildrenFromTenant || !watchTenant}
          options={childrenFromTenant || []}
          placeholder={'Wybierz dziecko'}
          getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
          getOptionValue={(o) => o.id}
        />
      </FormSection>
      <FormSection title="Data noty">
        <FormDatePicker control={control} name={'date'} />
      </FormSection>
    </ReportModalForm>
  );
};
