import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { FormDatePicker } from '@/components/form/FormDatePicker';
import { FormSection } from '@/components/form/FormSection';
import { Domain } from '@/features/domains';

import { ReportFormProps } from '../../types';
import { blobToJsonAsync } from '../../utils/blobToJson';
import { downloadReport } from '../../utils/downloadReport';
import { ReportModalForm } from '../ReportModalForm';
import { SchemasPicker } from '../SchemasPicker';

type Form = {
  year: Date | null;
  schemas: Array<Domain>;
};

const defaultValues: Form = {
  year: new Date(),
  schemas: [],
};

const schema = yup.object({
  year: yup.date().max(new Date(), 'Podaj prawidłowy rok'),
  schemas: yup.array().min(1, 'Pole wymagane'),
});

export const AnalysisOfStayAndCateringFeesForTheYearForm = (props: ReportFormProps) => {
  const { report } = props;
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm<Form>({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (values: Form) => {
    try {
      await downloadReport(report, {
        reportType: report?.reportType,
        reportFormat: report?.reportFormat,
        schemas: values.schemas.map((domain) => domain.domain),
        year: parseInt(dayjs(values.year).format('YYYY'), 10),
      });
      props.onClose();
    } catch (err: any) {
      const error = await blobToJsonAsync(err.response.data);
      toast.error(error.message);
    }
  };

  return (
    <ReportModalForm
      {...props}
      onSubmit={handleSubmit(handleFormSubmit)}
      isSubmitting={isSubmitting}
    >
      <FormSection title={'Placówki'}>
        <SchemasPicker control={control} />
      </FormSection>
      <FormSection title={'Wybór roku analizy'}>
        <FormDatePicker control={control} showYearPicker name={'year'} dateFormat={'yyyy'} />
      </FormSection>
    </ReportModalForm>
  );
};
