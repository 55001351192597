import { Divider, HStack, Icon, ListItemProps, Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { MdPictureAsPdf } from 'react-icons/md';
import { SiMicrosoftexcel } from 'react-icons/si';

import { MenuListItem } from '@/components/ui/MenuList/MenuListItem';

import { Report } from '../types';

import { ReportsListItemSkeleton } from './ReportsListItemSkeleton';

type Props = ListItemProps & {
  report: Report;
  isLoading: boolean;
};

export const ReportsListItem = ({ report, isLoading, ...props }: Props) => {
  const icon = useMemo(() => {
    switch (report.reportFormat) {
      case 'pdf':
        return MdPictureAsPdf;
      case 'xlsx':
        return SiMicrosoftexcel;
      default:
        return null;
    }
  }, [report.reportFormat]);

  if (isLoading) return <ReportsListItemSkeleton />;

  return (
    <>
      <MenuListItem {...props}>
        <HStack justifyContent={'space-between'}>
          <Text>{report.reportName}</Text>
          {icon ? <Icon as={icon} fontSize={'xl'} color={'blackAlpha.700'} /> : null}
        </HStack>
      </MenuListItem>
      <Divider borderColor="blackAlpha.500" />
    </>
  );
};
