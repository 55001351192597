import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/dist/query';

import { logout } from '@/features/auth';
// import { LoginResponse } from '@/features/auth/types';
import { RootState } from '@/stores';

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_V1,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    // try to get a new token
    // const response = await baseQuery('/refreshToken', api, extraOptions);
    // if (response.data) {
    //   const token = response.data as LoginResponse
    //   // store the new token
    //   api.dispatch(authenticationSuccess(response.data));
    //   // retry the initial query
    //   result = await baseQuery(args, api, extraOptions);
    // } else {
    api.dispatch(logout());
    // }
  }
  return result;
};
