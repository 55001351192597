import { Stack, Text } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import React, { useMemo } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import Paper from '@/components/ui/Paper';

import { useSummaryQuery } from '../api/dashboardApi';

export const AggregatedAttendanceStats = () => {
  const { data: summary, isLoading: isLoadingSummary } = useSummaryQuery();

  const aggregatedStats = useMemo(() => {
    return summary?.reduce(
      (prev, curr) => {
        return {
          enrolled: prev.enrolled + parseInt(curr.enrolled, 10),
          present: prev.present + parseInt(curr.present, 10),
          absent: prev.absent + parseInt(curr.absent, 10),
        };
      },
      {
        enrolled: 0,
        present: 0,
        absent: 0,
      }
    );
  }, [summary]);

  if (isLoadingSummary) {
    return (
      <>
        <Skeleton>Wszystkie placówki</Skeleton>
        <Skeleton>
          <Text>Wszystkie dzieci: 120</Text>
        </Skeleton>
        <Skeleton>
          <Text>Wszystkich dzieci: 120</Text>
        </Skeleton>
        <Skeleton>
          <Text>Wszystkich dzieci: 120</Text>
        </Skeleton>
      </>
    );
  }

  if (!aggregatedStats) return null;

  return (
    <Paper p={4} color={'black'}>
      <Stack>
        <Text fontSize={'xl'} fontWeight={'medium'}>
          Obecność w placówkach
        </Text>
        <Text>Wszystkie dzieci: {aggregatedStats.enrolled}</Text>
        <Text>Obecne: {aggregatedStats.present}</Text>
        <Text>Nieobecne: {aggregatedStats.absent}</Text>
      </Stack>
      <ResponsiveContainer width={'100%'} height={300}>
        <BarChart
          data={summary}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis hide dataKey="domain.name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="present" name={'Obecne'} stackId="a" fill="#805AD5" />
          <Bar dataKey="absent" name={'Nieobecne'} stackId="a" fill={'#D53F8C'} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};
