import { Box, Text } from '@chakra-ui/react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useEffect, useState, useCallback } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { Control, Controller, useWatch } from 'react-hook-form';

import './EditorStyles.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

type Props = {
  control: Control<any>;
  name: string;
};

const FormTextEditor = ({ control, name }: Props) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const value = useWatch({ control, name });

  const decodeHtmlEntities = (str: string) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  };

  useEffect(() => {
    if (value && typeof value === 'string') {
      const contentBlock = htmlToDraft(decodeHtmlEntities(value));
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const newEditorState = EditorState.createWithContent(contentState);
        setEditorState(newEditorState);
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditorStateChange = useCallback(
    (state: EditorState, onChange: (value: string) => void) => {
      const parsedToHTML = draftToHtml(convertToRaw(state.getCurrentContent()));
      onChange(parsedToHTML);
      setEditorState(state);
    },
    []
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          <Box
            style={{
              width: '100%',
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
              border: `1px solid ${error?.message ? 'red' : 'black'}`,
              borderRadius: '2px',
              fontFamily: "Roboto Helvetica Arial 'sans-serif' !important",
            }}
          >
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorStyle={{ height: 200, padding: 0 }}
              onEditorStateChange={(state) => onEditorStateChange(state, onChange)}
              localization={{
                locale: 'pl',
              }}
              toolbar={{
                options: [
                  'emoji',
                  'inline',
                  'blockType',
                  'fontSize',
                  'fontFamily',
                  'list',
                  'textAlign',
                  'colorPicker',
                  'link',
                  'remove',
                ],
                inline: { inDropdown: true },
                list: { inDropdown: true },
                textAlign: { inDropdown: true },
                link: { inDropdown: true, popupClassName: 'custom-link-popup' },
                history: { inDropdown: true },
              }}
            />
          </Box>
          {error?.message && (
            <Text
              style={{
                color: 'red',
                fontSize: '14px',
                margin: '8px 0',
              }}
            >
              {error?.message}
            </Text>
          )}
        </>
      )}
    />
  );
};

export default FormTextEditor;
