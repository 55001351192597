import { SimpleGrid, Stack, Text } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import { FormDatePicker } from '@/components/form/FormDatePicker';
import { FormSection } from '@/components/form/FormSection';
import { Domain } from '@/features/domains';

import { ReportFormProps } from '../../types';
import { blobToJsonAsync } from '../../utils/blobToJson';
import { downloadReport } from '../../utils/downloadReport';
import { ReportModalForm } from '../ReportModalForm';
import { SchemasPicker } from '../SchemasPicker';

type Form = {
  yearStart: Date | null;
  yearEnd: Date | null;
  schemas: Array<Domain>;
};

const INITIAL_VALUES = {
  yearStart: new Date(),
  yearEnd: new Date(),
  schemas: [],
};

const schema = yup.object({
  yearStart: yup.date().max(new Date(), 'Podaj prawidłowy rok'),
  yearEnd: yup.date().max(new Date(), 'Podaj prawidłowy rok'),
  schemas: yup.array().min(1, 'Pole wymagane'),
});

export const StatementOfIncomeInNurseriesInYearsForm = (props: ReportFormProps) => {
  const { report } = props;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Form>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (values: Form) => {
    try {
      await downloadReport(report, {
        reportType: report.reportType,
        reportFormat: report.reportFormat,
        schemas: values.schemas.map((domain) => domain.domain),
        yearStart: dayjs(values.yearStart).format('YYYY'),
        yearEnd: dayjs(values.yearEnd).format('YYYY'),
      });
      props.onClose();
    } catch (err: any) {
      const error = await blobToJsonAsync(err.response.data);
      toast.error(error.message);
    }
  };

  return (
    <ReportModalForm
      {...props}
      onSubmit={handleSubmit(handleFormSubmit)}
      isSubmitting={isSubmitting}
    >
      <FormSection title={'Placówki'}>
        <SchemasPicker control={control} />
      </FormSection>
      <FormSection title={'Zakres lat do zestawienia'}>
        <SimpleGrid columns={2} gap={4}>
          <Stack>
            <Text>Rok od</Text>
            <FormDatePicker
              control={control}
              showYearPicker
              name={'yearStart'}
              dateFormat={'yyyy'}
            />
          </Stack>
          <Stack>
            <Text>Rok do</Text>
            <FormDatePicker control={control} showYearPicker name={'yearEnd'} dateFormat={'yyyy'} />
          </Stack>
        </SimpleGrid>
      </FormSection>
    </ReportModalForm>
  );
};
