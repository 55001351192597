import { instance } from '@/lib';

import { Report } from '../types';

import { saveBlob } from './saveBlob';

export const downloadReport = async (report: Report, params: any) => {
  const response = await instance.get('/reports/download', {
    params: {
      reportType: report.reportType,
      reportFormat: report.reportFormat,
      ...params,
    },
    responseType: 'blob',
  });
  saveBlob(response, report.reportName, report.reportFormat);
};
