import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from '@chakra-ui/react';
import React from 'react';

type Props = ModalProps & {
  title: string;
  saveButtonLabel?: string;
  cancelButtonLabel?: string;
  formId: string;
  isSubmitting?: boolean;
  onSubmit: React.FormEventHandler;
};

export const ModalForm = ({
  title,
  saveButtonLabel,
  cancelButtonLabel,
  children,
  formId,
  onSubmit,
  isSubmitting,
  ...props
}: Props) => {
  if (!props.isOpen) return null;
  return (
    <Modal {...props} size={'xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack as={'form'} id={formId} align={'flex-start'} spacing={8} onSubmit={onSubmit}>
            {children}
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button onClick={props.onClose} variant={'ghost'}>
            {cancelButtonLabel || 'Anuluj'}
          </Button>
          <Button
            type="submit"
            form={formId}
            variant={'solid'}
            isLoading={isSubmitting}
            colorScheme={'purple'}
          >
            {saveButtonLabel || 'Zapisz'}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
