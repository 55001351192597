import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { Control, useController } from 'react-hook-form';

import FormInput from './FormInput';

type Props = Omit<DatePicker['props'], 'onChange'> & {
  name: string;
  control: Control<any>;
};

const DatePickerInput = forwardRef<HTMLInputElement, React.ComponentProps<typeof FormInput>>(
  (props, ref) => <FormInput ref={ref} {...props} />
);
DatePickerInput.displayName = 'DatePickerInput';

export const FormDatePicker = ({ name, control, ...props }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  return (
    <DatePicker
      selected={field.value}
      onChange={(date) => field.onChange(date)}
      dateFormat={'dd/MM/yyyy'}
      locale={'pl-PL'}
      {...props}
      customInput={<DatePickerInput error={error} name={`${name}-DatePicker`} />}
    />
  );
};
