import 'react-quill/dist/quill.snow.css'; // Imp

const Card = ({ children, ...rest }: { children: React.ReactNode }) => {
  return (
    <div
      style={{
        border: '1px solid #e0e0e0',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        background: 'white',

        width: '100%',
        padding: '16px',
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

export default Card;
