import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdDelete, MdFullscreen } from 'react-icons/md';

import Lightbox from '@/components/ui/Lightbox/Lightbox';

const PhotoGallery = ({
  photos,
  onRemoveFile,
  preview = false,
}: {
  photos: Array<any>;
  onRemoveFile?: any;
  preview?: boolean;
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [lightboxIsOpen, setLightboxOpen] = useState(false);

  const urls = photos?.map((photo) => ({
    src: photo.thumbnailStorageUrl?.startsWith('blob')
      ? photo.thumbnailStorageUrl
      : photo.fileStorageUrl,
    caption: photo.name,
    photoId: photo.id,
  }));

  const openLightbox = (index: number) => {
    setCurrentImage(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setLightboxOpen(false);
  };

  return (
    <Box style={{ margin: 16 }}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {photos.length && preview ? (
          <>
            <GridItem
              w="100%"
              h="130"
              bg="blue.500"
              key={'first-element-file'}
              style={{ overflow: 'hidden', position: 'relative' }}
            >
              <img
                src={
                  photos[0].thumbnailStorageUrl?.startsWith('blob')
                    ? photos[0].thumbnailStorageUrl
                    : photos[0].fileStorageUrl
                }
                alt={photos[0].name}
                title="Powiększ"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />

              {
                <Box
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    height: 48,
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                >
                  {!preview && (
                    <MdDelete
                      style={{ color: 'white', fontSize: 28, margin: 10, cursor: 'pointer' }}
                      onClick={() => onRemoveFile(photos[0].id)}
                    />
                  )}

                  <MdFullscreen
                    style={{ color: 'white', fontSize: 28, margin: 10, cursor: 'pointer' }}
                    onClick={() => openLightbox(0)}
                  />
                </Box>
              }
            </GridItem>
            {photos.length > 1 && (
              <GridItem>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '42px',
                    background: 'rgba(0,0,0,0.4)',
                    color: 'white',
                    height: '100%',
                  }}
                >
                  <span>+{photos.length - 1} </span>
                </Box>
              </GridItem>
            )}
          </>
        ) : (
          photos.map((photo, index) => (
            <GridItem
              w="100%"
              h="130"
              bg="blue.500"
              key={index}
              style={{ overflow: 'hidden', position: 'relative' }}
            >
              <Text onClick={() => openLightbox(index)}>
                <img
                  src={
                    photo.thumbnailStorageUrl?.startsWith('blob')
                      ? photo.thumbnailStorageUrl
                      : photo.fileStorageUrl
                  }
                  alt={photo.name}
                  title="Powiększ"
                  style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
                />
              </Text>
              {!preview && (
                <Box
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    width: '100%',
                    height: 48,
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center',
                  }}
                >
                  <MdDelete
                    style={{ color: 'white', fontSize: 28, margin: 10, cursor: 'pointer' }}
                    onClick={() => onRemoveFile(photo.id)}
                  />

                  <MdFullscreen
                    style={{ color: 'white', fontSize: 28, margin: 10, cursor: 'pointer' }}
                    onClick={() => openLightbox(index)}
                  />
                </Box>
              )}
            </GridItem>
          ))
        )}
      </Grid>

      <Lightbox
        isOpen={lightboxIsOpen}
        onClose={closeLightbox}
        onRemove={onRemoveFile}
        images={urls}
        initialIndex={currentImage}
      />
    </Box>
  );
};

export default PhotoGallery;
