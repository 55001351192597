import { Input, InputGroup, InputProps, InputRightElement } from '@chakra-ui/react';
import React from 'react';
import { MdSearch } from 'react-icons/md';

export const SearchInput = (props: InputProps) => {
  return (
    <InputGroup maxWidth={'250px'}>
      <Input _placeholder={{ color: 'white' }} placeholder="Szukaj" {...props} />
      <InputRightElement>
        <MdSearch />
      </InputRightElement>
    </InputGroup>
  );
};
