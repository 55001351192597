import { Avatar, HStack, Skeleton, SkeletonCircle, Text } from '@chakra-ui/react';
import React from 'react';

import Paper from '@/components/ui/Paper';

import { useMeQuery } from '../api';

export const CurrentUserAvatar = () => {
  const { data: me, isLoading: isLoadingMe } = useMeQuery();

  if (isLoadingMe) {
    return (
      <Paper bg={'whiteAlpha.200'} p={4}>
        <HStack>
          <SkeletonCircle>
            <Avatar size={'sm'} name={'B 2 C'} />
          </SkeletonCircle>
          <Skeleton>
            <Text>{'Przykładowy użyszkodnik'}</Text>
          </Skeleton>
        </HStack>
      </Paper>
    );
  }

  return (
    <Paper bg={'whiteAlpha.200'} p={4}>
      <HStack>
        <Avatar size={'sm'} name={me?.user.name} />
        <Text>{me?.user.name}</Text>
      </HStack>
    </Paper>
  );
};
