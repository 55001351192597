import { Button, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import FormInput from '@/components/form/FormInput';
import { useAppDispatch } from '@/hooks/store';
import { instance } from '@/lib';

import { useLoginMutation } from '../api';
import { authenticationSuccess } from '../stores/authSlice';

type Form = {
  email: string;
  password: string;
};

const schema = yup.object({
  email: yup.string().required('Pole wymagane').email('Podaj poprawny adres e-mail'),
  password: yup.string().required('Pole jest wymagane'),
});

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(schema),
  });

  const dispatch = useAppDispatch();
  const [login] = useLoginMutation();

  const onSubmit: SubmitHandler<Form> = async (data) => {
    try {
      const response = await login(data).unwrap();
      const token = response.tokens.access.token;
      localStorage.setItem('access_token', token);
      dispatch(authenticationSuccess(token));
      instance.defaults.headers['Authorization'] = `Bearer ${token}`;
    } catch (err: any) {
      console.log(err);
      toast.error(err.data.message);
    }
  };

  return (
    <Stack w={'full'} as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
      <FormInput placeholder="Email" {...register('email')} error={errors.email} />
      <FormInput
        placeholder="Hasło"
        {...register('password')}
        error={errors.password}
        type={'password'}
      />
      <Button colorScheme={'purple'} type={'submit'} isLoading={isSubmitting}>
        Zaloguj się
      </Button>
    </Stack>
  );
};

export default LoginForm;
