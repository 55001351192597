import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
} from '@chakra-ui/react';
import React, { Ref } from 'react';
import { FieldError } from 'react-hook-form';

type Props = InputProps & {
  error?: FieldError;
  label?: string;
  rightAddon?: string;
  leftAddon?: string;
};

const FormInput = React.forwardRef(
  ({ error, label, rightAddon, leftAddon, ...rest }: Props, ref: Ref<HTMLInputElement>) => {
    return (
      <FormControl isInvalid={error && !!error.message}>
        {label ? <FormLabel htmlFor={rest.name}>{label}</FormLabel> : null}
        <InputGroup>
          {leftAddon ? <InputLeftAddon>{leftAddon}</InputLeftAddon> : null}
          <Input ref={ref} id={rest.name} {...rest} />
          {rightAddon ? <InputRightAddon>{rightAddon}</InputRightAddon> : null}
        </InputGroup>
        <FormErrorMessage>{error?.message}</FormErrorMessage>
      </FormControl>
    );
  }
);

FormInput.displayName = 'FormInput';

export default FormInput;
