import React, { ComponentProps } from 'react';

import { ModalForm } from '@/components/ui/Modal/ModalForm';

import { Report } from '../types';

type Props = Omit<ComponentProps<typeof ModalForm>, 'formId' | 'title'> & {
  report: Report;
};

export const ReportModalForm = ({ report, ...props }: Props) => {
  return (
    <ModalForm
      saveButtonLabel={'Pobierz raport'}
      {...props}
      formId={`${report.reportType}Form`}
      title={report.reportName}
    />
  );
};
