import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';
import React from 'react';
import { MdChevronRight, MdHome } from 'react-icons/md';
import { Link } from 'react-router-dom';

import { Breadcrumb as BreadcrumbObject } from '@/types/Breadcrumbs';

type Props = {
  breadcrumbs: Array<BreadcrumbObject>;
  omitStartRoute?: boolean;
};

const startRoute: BreadcrumbObject = {
  path: '/app',
  displayValue: <MdHome />,
};

export const Breadcrumbs = ({ breadcrumbs, omitStartRoute = false }: Props) => {
  return (
    <Breadcrumb spacing="8px" separator={<MdChevronRight color="gray.500" />}>
      {[startRoute, ...breadcrumbs]
        .filter((crumb) => (omitStartRoute ? crumb.path !== startRoute.path : true))
        .map((crumb) => (
          <BreadcrumbItem key={crumb.path} isCurrentPage={crumb.isCurrentRoute}>
            {crumb.isCurrentRoute ? (
              <BreadcrumbLink>{crumb.displayValue}</BreadcrumbLink>
            ) : (
              <BreadcrumbLink as={Link} to={crumb.path}>
                {crumb.displayValue}
              </BreadcrumbLink>
            )}
          </BreadcrumbItem>
        ))}
    </Breadcrumb>
  );
};
