import { Box, HStack, Stack } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import DrawerMenu from '@/components/ui/Navigation/DrawerMenu';
import { useAuth } from '@/features/auth';
import { boardPostsRoutes } from '@/features/boardPosts';
import { dashboardRoutes } from '@/features/dashboard';
import { reportsRoutes } from '@/features/reports';
// import { usersRoutes } from '@/features/users';

const AppWrapper = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuth) {
      navigate('/');
    }
  }, [isAuth, navigate]);
  return (
    <HStack align={'start'} w={'100%'}>
      <DrawerMenu />
      <Stack w={'100%'}>
        <Box h={`100vh`} overflowY={'auto'} p={8}>
          <Outlet />
        </Box>
      </Stack>
    </HStack>
  );
};

export const protectedRoutes = [
  {
    path: 'app',
    element: <AppWrapper />,
    children: [
      { path: '', children: dashboardRoutes },
      { path: 'reports', children: reportsRoutes },
      { path: 'boardPosts', children: boardPostsRoutes },
      // { path: 'users', children: usersRoutes },
    ],
  },
];
