import { SimpleGrid, Stack, Text, Box } from '@chakra-ui/layout';
import { IconButton, Icon } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { ImNewTab } from 'react-icons/im';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import Paper from '@/components/ui/Paper';

import { Summary } from '../types';

type Props = {
  summary: Summary;
  isLoading: boolean;
};

export const DomainStats = ({ summary }: Props) => {
  const data = useMemo(() => {
    return [
      { value: parseInt(summary.present, 10), name: 'Obecni' },
      {
        value: parseInt(summary.absent, 10),
        name: 'Nieobecni',
      },
    ];
  }, [summary]);

  const handleRedirect = () => {
    const domain = summary.domain.domain;
    window.open(`https://${domain}.4parents.pl`, '_blank');
  };

  return (
    <Paper
      _hover={{ bg: 'purple.100', cursor: 'pointer' }}
      transition="background-color 0.3s ease-out"
      key={summary.domain.domain}
      color={'black'}
      p={4}
      onClick={handleRedirect}
    >
      <SimpleGrid columns={2}>
        <Stack>
          <Text fontSize={'xl'} fontWeight={'medium'}>
            {summary.domain.name}
          </Text>
          <Text>Wszystkie dzieci: {summary.enrolled}</Text>
          <Text>Obecne: {summary.present}</Text>
          <Text>Nieobecne: {summary.absent}</Text>
        </Stack>
        <Box>
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              bg={'transparent'}
              aria-label="Open link in new tab"
              icon={<Icon as={ImNewTab} />}
              _hover={{ transform: 'scale(1.2)' }}
              transform="scale(1)"
              transition="transform 0.2s ease-in-out"
            />
          </Box>
          <ResponsiveContainer width={'100%'} height={150}>
            <PieChart>
              <Pie dataKey="value" data={data} cx={'50%'} cy={'50%'}>
                <Cell fill={'#805AD5'} />
                <Cell fill={'#D53F8C'} />
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </Box>
      </SimpleGrid>
    </Paper>
  );
};
