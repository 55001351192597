import { ChakraProvider } from '@chakra-ui/react';
import { pl } from 'date-fns/locale';
import { registerLocale } from 'react-datepicker';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { appRoutes } from '@/routes';
import { store } from '@/stores';

const router = createBrowserRouter(appRoutes);

registerLocale('pl-PL', pl);

function App() {
  return (
    <Provider store={store}>
      <ChakraProvider resetCSS>
        <RouterProvider router={router} />
      </ChakraProvider>
    </Provider>
  );
}

export default App;
