import { Modal, ModalOverlay, ModalContent, Image, IconButton, Flex, Box } from '@chakra-ui/react';
import React, { useState, useEffect } from 'react';
import { MdArrowLeft, MdArrowRight, MdClose, MdDelete } from 'react-icons/md';

const Lightbox = ({
  isOpen,
  onClose,
  onRemove,
  images,
  initialIndex = 0,
}: {
  isOpen: boolean;
  onClose: () => void;
  onRemove?: (arg0: string) => void;
  images: Array<{ src: string; caption: string; photoId: string }>;
  initialIndex?: number;
}) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  useEffect(() => {
    if (isOpen) {
      setCurrentIndex(initialIndex);
    }
  }, [isOpen, initialIndex]);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  if (!images || images.length === 0) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay style={{ background: 'rgba(0,0,0,0.6)' }} />
      <ModalContent
        style={{
          position: 'absolute',
          background: 'transparent',
          paddingLeft: 50,
          paddingRight: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Box
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            color: 'white',
            background: 'rgba(0,0,0,0.5)',
            height: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            paddingLeft: 20,
          }}
        >
          {onRemove && (
            <MdDelete
              style={{ color: 'white', fontSize: 28, margin: 10, cursor: 'pointer' }}
              onClick={() => {
                onRemove(images[currentIndex]?.photoId);
                onClose();
              }}
            />
          )}
          <MdClose
            style={{ color: 'white', fontSize: 28, margin: 10, cursor: 'pointer' }}
            onClick={() => onClose()}
          />
        </Box>
        <Flex
          position="absolute"
          top="50%"
          width="100vw"
          justifyContent="space-between"
          style={{ zIndex: 999 }}
        >
          <IconButton
            icon={<MdArrowLeft />}
            aria-label="Previous"
            onClick={handlePrevious}
            bg="rgba(0,0,0,0.5)"
            color="white"
            _hover={{ bg: 'rgba(0,0,0,0.5)' }}
            borderRadius="none"
            position="absolute"
            left="0"
          />
          <IconButton
            icon={<MdArrowRight />}
            aria-label="Next"
            onClick={handleNext}
            bg="rgba(0,0,0,0.5)"
            color="white"
            _hover={{ bg: 'rgba(0,0,0,0.5)' }}
            borderRadius="none"
            position="absolute"
            right="0"
          />
        </Flex>
        <Image
          src={images[currentIndex]?.src}
          alt={images[currentIndex]?.caption}
          maxHeight="80vh"
          style={{ padding: 50 }}
        />
        <Box
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            color: 'white',
            background: 'rgba(0,0,0,0.5)',
            height: 40,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 20,
          }}
        >
          {currentIndex + 1} z {images.length}
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default Lightbox;
