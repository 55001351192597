import { Flex, HStack, SimpleGrid, Skeleton, Stack } from '@chakra-ui/react';
import React from 'react';

import { Header } from '@/components/ui/Header/Header';
import { useMeQuery } from '@/features/auth';

import { useSummaryQuery } from '../api/dashboardApi';
import { AggregatedAttendanceStats } from '../components/AggregatedAttendanceStats';
import { AggregatedSettlementsStats } from '../components/AggregatedSettlementsStats';
import { DomainStats } from '../components/DomainStats';

export const DashboardPage = () => {
  const { data: user, isLoading: isLoadingUser } = useMeQuery();
  const {
    data: summary,
    isLoading: isLoadingSummary,
    isFetching: isFetchingSummary,
  } = useSummaryQuery();

  return (
    <Stack spacing={4}>
      <HStack pb={4}>
        {isLoadingUser ? (
          <Skeleton>
            <Header>Witaj, lorem ipsum</Header>
          </Skeleton>
        ) : (
          <Header>Witaj, {user?.user.name}</Header>
        )}

        <Flex flexGrow={1} />
      </HStack>
      <SimpleGrid gap={4} minChildWidth="480px">
        <AggregatedAttendanceStats />
        <AggregatedSettlementsStats />
      </SimpleGrid>
      <SimpleGrid gap={4} minChildWidth="260px">
        {summary?.map((domainSummary) => (
          <DomainStats
            key={domainSummary.domain.domain}
            summary={domainSummary}
            isLoading={isLoadingSummary || isFetchingSummary}
          />
        ))}
      </SimpleGrid>
    </Stack>
  );
};
