import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FormDatePicker } from '@/components/form/FormDatePicker';
import { FormReactSelect } from '@/components/form/FormReactSelect';
import { FormSection } from '@/components/form/FormSection';
import { Domain, useDomainsQuery } from '@/features/domains';

import { useChildrenFromTenantQuery } from '../../api/reportsApi';
import { Child, ReportFormProps } from '../../types';
import { downloadReport } from '../../utils/downloadReport';
import { handleDownloadReportError } from '../../utils/handleDownloadReportError';
import { ReportModalForm } from '../ReportModalForm';

type Form = {
  domain: Domain | null;
  child: Child | null;
  startMonth: Date | null;
};

const defaultValues: Form = {
  startMonth: new Date(),
  domain: null,
  child: null,
};

const schema = yup.object({
  startMonth: yup.date().required('Pole wymagane'),
  domain: yup.object().nullable().required('Pole wymagane'),
  child: yup.object().nullable().required('Pole wymagane'),
});

export const FileOfFeesForChildStayForm = (props: ReportFormProps) => {
  const { report } = props;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = useForm<Form>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const domain = watch('domain');

  const { data: domains, isLoading: isLoadingDomains } = useDomainsQuery();
  const {
    data: children,
    isLoading: isLoadingChildren,
    isFetching: isFetchingChildren,
  } = useChildrenFromTenantQuery(domain?.domain as string, {
    skip: !domain,
  });

  const handleFormSubmit = async (values: Form) => {
    try {
      await downloadReport(report, {
        startMonth: dayjs(values.startMonth).format('YYYY-MM'),
        domain: values.domain?.domain as string,
        childId: values.child?.id as string,
      });
      props.onClose();
    } catch (err: any) {
      handleDownloadReportError(err);
    }
  };

  return (
    <ReportModalForm
      {...props}
      onSubmit={handleSubmit(handleFormSubmit)}
      isSubmitting={isSubmitting}
    >
      <FormSection title={'Placówka'}>
        <FormReactSelect
          control={control}
          name={'domain'}
          options={domains || []}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.domain}
          placeholder={'Wybierz placówkę'}
          isLoading={isLoadingDomains}
        />
      </FormSection>
      <FormSection title={'Dziecko'}>
        <FormReactSelect
          control={control}
          name={'child'}
          options={children || []}
          getOptionLabel={(o) => `${o.firstName} ${o.lastName}`}
          getOptionValue={(o) => o.id}
          placeholder={'Wybierz dziecko'}
          isDisabled={!domain || isFetchingChildren}
          isLoading={isFetchingChildren || isLoadingChildren}
        />
      </FormSection>
      <FormSection title={'Pierwszy miesiąc'}>
        <FormDatePicker
          control={control}
          name={'startMonth'}
          placeholderText={'Wybierz miesiąc początkowy'}
          showMonthYearPicker
          dateFormat={'MM-yyyy'}
        />
      </FormSection>
    </ReportModalForm>
  );
};
