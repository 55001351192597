import { Divider, Skeleton } from '@chakra-ui/react';
import React from 'react';

import { MenuListItem } from '@/components/ui/MenuList/MenuListItem';

export const ReportsListItemSkeleton = () => {
  return (
    <>
      <MenuListItem _hover={{}}>
        <Skeleton>Lorem ipsum dolor sit ament</Skeleton>
      </MenuListItem>
      <Divider borderColor="blackAlpha.500" />
    </>
  );
};
