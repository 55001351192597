import { baseApi } from '@/lib';

import {
  AddBoardPostRequestDTO,
  AddBoardPostResponseDTO,
  BoardPostRequestDTO,
  BoardPostResponseDTO,
  BoardPostsResponseDTO,
  DeleteBoardPostRequestDTO,
  UpdateBoardPostRequestDTO,
  UpdateBoardPostResponseDTO,
} from '../dto/boardPostsDTO';

const enchancedApi = baseApi.enhanceEndpoints({
  addTagTypes: ['BoardPosts'],
});

export const boardPostsApi = enchancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getBoardPosts: builder.query<BoardPostsResponseDTO, void>({
      query: () => 'boardPosts',
      providesTags: ['BoardPosts'],
    }),
    getBoardPost: builder.query<BoardPostResponseDTO, BoardPostRequestDTO>({
      query: ({ id }) => `boardPosts/${id}`,
    }),
    addBoardPost: builder.mutation<AddBoardPostResponseDTO, AddBoardPostRequestDTO>({
      query: ({ title, content, pinned, schemas, files }) => ({
        url: `boardPosts`,
        method: 'POST',
        body: { title, content, pinned, schemas, files },
      }),
      invalidatesTags: ['BoardPosts'],
      //   invalidatesTags: (_, __, query) => [{ type: 'Users', id: query.userId }],
    }),
    updateBoardPost: builder.mutation<UpdateBoardPostResponseDTO, UpdateBoardPostRequestDTO>({
      query: ({ globalId, title, content, pinned, files }) => ({
        url: `boardPosts/${globalId}`,
        method: 'PATCH',
        body: { title, content, pinned, files },
      }),
      invalidatesTags: ['BoardPosts'],
    }),
    deleteBoardPost: builder.mutation<void, DeleteBoardPostRequestDTO>({
      query: ({ globalId }) => ({
        url: `boardPosts/${globalId}`,
        method: 'DELETE',
        body: { globalId },
      }),
      invalidatesTags: ['BoardPosts'],
    }),
  }),
});

export const {
  useAddBoardPostMutation,
  useGetBoardPostsQuery,
  useGetBoardPostQuery,
  useUpdateBoardPostMutation,
  useDeleteBoardPostMutation,
} = boardPostsApi;
