import { Button, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import FormInput from '@/components/form/FormInput';
import { useRouteQuery } from '@/hooks/useRouteQuery';
import { instance } from '@/lib';

type Form = {
  password: string;
  repeatPassword: string;
};

const INITIAL_VALUES: Form = {
  password: '',
  repeatPassword: '',
};

const schema = yup.object({
  password: yup.string().required('Pole wymagane'),
  repeatPassword: yup
    .string()
    .equals([yup.ref('password')], 'Hasła muszą być takie same')
    .required('Pole wymagane'),
});

export const CreatePasswordForm = () => {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<Form>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(schema),
  });

  const token = useRouteQuery('token');
  const navigate = useNavigate();

  useEffect(() => {
    console.log('token', token);
    if (!token) navigate('/');
  }, [navigate, token]);

  const onSubmit = async (values: Form) => {
    try {
      await instance.post('/auth/verify-email', {
        token,
        password: values.password,
      });
      navigate('/account-activated');
    } catch (err: any) {
      toast.error(err.data.message);
    }
  };

  return (
    <Stack w={'full'} as="form" spacing={4} onSubmit={handleSubmit(onSubmit)}>
      <FormInput
        error={errors.password}
        placeholder="Hasło"
        {...register('password')}
        type={'password'}
      />
      <FormInput
        error={errors.repeatPassword}
        placeholder="Powtórz hasło"
        {...register('repeatPassword')}
        type={'password'}
      />
      <Button colorScheme={'purple'} type={'submit'} isLoading={isSubmitting}>
        Utwórz hasło
      </Button>
    </Stack>
  );
};
