import { Box, HStack, Stack, Text } from '@chakra-ui/layout';
import { Skeleton } from '@chakra-ui/skeleton';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { DatePicker } from '@/components/form/DatePicker';
import Paper from '@/components/ui/Paper';

import { usePaidSettlementsSummaryQuery } from '../api/dashboardApi';

export const AggregatedSettlementsStats = () => {
  const [month, setMonth] = useState<Date | null>(new Date());

  const { data, isLoading: isLoadingSettlements } = usePaidSettlementsSummaryQuery({
    month: dayjs(month).format('YYYY-MM'),
  });

  const settlements = useMemo(() => {
    return data?.map((stats) => ({
      ...stats,
      notPaid: `${parseInt(stats.total, 10) - parseInt(stats.paid, 10)}`,
    }));
  }, [data]);

  const aggregatedStats = useMemo(() => {
    return settlements?.reduce(
      (prev, curr) => {
        return {
          paid: prev.paid + parseInt(curr.paid, 10),
          punctually: prev.punctually + parseInt(curr.punctually, 10),
          total: prev.total + parseInt(curr.total, 10),
        };
      },
      {
        paid: 0,
        punctually: 0,
        total: 0,
      }
    );
  }, [settlements]);

  if (isLoadingSettlements) {
    return (
      <>
        <Skeleton>Wszystkie placówki</Skeleton>
        <Skeleton>
          <Text>Wszystkich dzieci: 120</Text>
        </Skeleton>
        <Skeleton>
          <Text>Wszystkich dzieci: 120</Text>
        </Skeleton>
        <Skeleton>
          <Text>Wszystkich dzieci: 120</Text>
        </Skeleton>
      </>
    );
  }

  if (!aggregatedStats) return null;

  return (
    <Paper p={4} color={'black'}>
      <Stack>
        <HStack justify={'space-between'}>
          <Text fontSize={'xl'} fontWeight={'medium'}>
            Płatności we wszystkich placówkach w miesiącu
          </Text>
          <Box>
            <DatePicker
              selected={month}
              dateFormat={'MM/yyyy'}
              onChange={setMonth}
              showMonthYearPicker
            />
          </Box>
        </HStack>
        <Text>Wszystkie płatności: {aggregatedStats.total}</Text>
        <Text>
          Opłacone: {aggregatedStats.paid} (w tym {aggregatedStats.punctually} w terminie)
        </Text>
        <Text>Nieopłacone: {aggregatedStats.total - aggregatedStats.paid}</Text>
      </Stack>
      <ResponsiveContainer width={'100%'} height={300}>
        <BarChart
          data={settlements}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="domain.name" hide />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="paid" name={'Opłacone'} stackId="a" fill="#07bc0c" />
          <Bar dataKey="punctually" name={'W terminie'} stackId="b" fill={'#805AD5'} />
          <Bar dataKey="notPaid" name={'Nieopłacone'} stackId="a" fill={'#D53F8C'} />
        </BarChart>
      </ResponsiveContainer>
    </Paper>
  );
};
