import { List } from '@chakra-ui/react';

import Paper from '@/components/ui/Paper';

import { AccountingNoteForm } from './Forms/AccountingNoteForm';
import { AnalysisOfStayAndCateringFeesForTheYearForm } from './Forms/AnalysisOfStayAndCateringFeesForTheYearForm';
import { CertificateGdanskForm } from './Forms/CertificateGdanskForm';
import { ChildDailyStayRecordsForm } from './Forms/ChildDailyStayRecordsForm';
import { ChildrenAttendanceInDaysByGroupForm } from './Forms/ChildrenAttendanceInDaysByGroupForm';
import { FileOfFeesForChildStayForm } from './Forms/FileOfFeesForChildStayForm';
import { ListOfArrearsForMonthForm } from './Forms/ListOfArrearsForMonthForm';
import { ListOfFeesPaidForm } from './Forms/ListOfFeesPaidForm';
import { ListOfManDaysForm } from './Forms/ListOfManDaysForm';
import { ListOfTransfersForm } from './Forms/ListOfTransfersForm';
import { StatementOfAccrualsForMonthForm } from './Forms/StatementOfAccrualsForMonthForm';
import { StatementOfIncomeInNurseriesInYearsForm } from './Forms/StatementOfIncomeInNurseriesInYearsForm';
import { StatementOfInterestForm } from './Forms/StatementOfInterestForm';
import { StatementOfOverpaymentsForm } from './Forms/StatementOfOverpaymentsForm';
import { StatementOfSettlementsForMonthForm } from './Forms/StatementOfSettlementsForMonthForm';
import { Report } from './Report';

type Props = {
  searchValue: string;
};

const reports = [
  { reportType: 'accountingNote', ReportForm: AccountingNoteForm },
  {
    reportType: 'analysisOfStayAndCateringFeesForTheYear',
    ReportForm: AnalysisOfStayAndCateringFeesForTheYearForm,
  },
  {
    reportType: 'statementOfIncomeInNurseriesInYears',
    ReportForm: StatementOfIncomeInNurseriesInYearsForm,
  },
  { reportType: 'listOfTransfers', ReportForm: ListOfTransfersForm },
  { reportType: 'statementOfInterest', ReportForm: StatementOfInterestForm },
  { reportType: 'statementOfOverpayments', ReportForm: StatementOfOverpaymentsForm },
  { reportType: 'listOfArrearsForMonth', ReportForm: ListOfArrearsForMonthForm },
  { reportType: 'listOfManDays', ReportForm: ListOfManDaysForm },
  { reportType: 'childDailyStayRecords', ReportForm: ChildDailyStayRecordsForm },
  { reportType: 'fileOfFeesForChildStay', ReportForm: FileOfFeesForChildStayForm },
  {
    reportType: 'childrenAttendanceInDaysByGroup',
    ReportForm: ChildrenAttendanceInDaysByGroupForm,
  },
  { reportType: 'statementOfAccrualsForMonth', ReportForm: StatementOfAccrualsForMonthForm },
  { reportType: 'statementOfSettlementsForMonth', ReportForm: StatementOfSettlementsForMonthForm },
  { reportType: 'certificateGdansk', ReportForm: CertificateGdanskForm },
  { reportType: 'listOfFeesPaid', ReportForm: ListOfFeesPaidForm },
];

export const ReportsList = ({ searchValue }: Props) => {
  return (
    <Paper shadow={'lg'} p={4}>
      <List color={'initial'}>
        {reports.map((report) => (
          <Report key={report.reportType} {...report} filterString={searchValue} />
        ))}
      </List>
    </Paper>
  );
};
