import axios from 'axios';

const token = localStorage.getItem('access_token');

if (token) {
  axios.defaults.headers['Authorization'] = `Bearer ${token}`;
}

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_V1,
});
