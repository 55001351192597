import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = {
  isAuth: boolean;
  token: string;
};

const token = localStorage.getItem('access_token');

const initialState: State = {
  isAuth: !!token,
  token: token || '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    authenticationSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isAuth = true;
      state.token = payload;
    },
    logout: (state) => {
      state.isAuth = false;
      state.token = '';
    },
  },
});

export const { authenticationSuccess, logout } = authSlice.actions;
