import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FormDatePicker } from '@/components/form/FormDatePicker';
import { FormSection } from '@/components/form/FormSection';
import { Domain } from '@/features/domains';

import { ReportFormProps } from '../../types';
import { downloadReport } from '../../utils/downloadReport';
import { handleDownloadReportError } from '../../utils/handleDownloadReportError';
import { ReportModalForm } from '../ReportModalForm';
import { SchemasPicker } from '../SchemasPicker';

type Form = {
  month: Date | null;
  schemas: Array<Domain>;
};

const INITIAL_VALUES = {
  month: new Date(),
  schemas: [],
};

const schema = yup.object({
  month: yup.date().required('Pole wymagane'),
  schemas: yup.array().min(1, 'Pole wymagane'),
});

export const StatementOfAccrualsForMonthForm = (props: ReportFormProps) => {
  const { report } = props;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Form>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (values: Form) => {
    try {
      await downloadReport(report, {
        month: dayjs(values.month).format('YYYY-MM'),
        schemas: values.schemas.map((domain) => domain.domain),
      });
      props.onClose();
    } catch (err: any) {
      handleDownloadReportError(err);
    }
  };

  return (
    <ReportModalForm
      {...props}
      onSubmit={handleSubmit(handleFormSubmit)}
      isSubmitting={isSubmitting}
    >
      <FormSection title={'Placówki'}>
        <SchemasPicker control={control} />
      </FormSection>
      <FormSection title={'Miesiąc'}>
        <FormDatePicker
          control={control}
          name={'month'}
          placeholderText={'Wybierz miesiąc'}
          showMonthYearPicker
          dateFormat={'MM-yyyy'}
        />
      </FormSection>
    </ReportModalForm>
  );
};
