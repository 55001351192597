import Layout from '@/components/ui/Layout/Layout';

import { protectedRoutes } from './protectedRoutes';
import { publicRoutes } from './publicRoutes';

export const appRoutes = [
  {
    path: '/',
    element: <Layout />,
    children: [...publicRoutes, ...protectedRoutes],
  },
];
