import { Box, Button, Link, List, ListItem, Tooltip } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import React from 'react';
import { MdAttachFile, MdDelete } from 'react-icons/md';

const handleDownloadFile = (file: any) => {
  const path = `${file.fileStorageUrl}?name=${encodeURIComponent(
    file.name
  )}&mimeType=${encodeURIComponent(file.mimeType)}`;
  window.open(path, '_blank');
};

const Files = ({
  files,
  onRemoveFile,
  preview,
}: {
  files: Array<any>;
  onRemoveFile: any;
  preview?: boolean;
}) => {
  return (
    <>
      {files.length > 0 ? (
        <List>
          {files.map((file: { id: string; name: string }) => (
            <ListItem
              key={file.id}
              aria-label={`Pobierz załącznik ${file.name}`}
              style={{ listStyle: 'none' }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 16,
                }}
              >
                <Link
                  onClick={() => handleDownloadFile(file)}
                  style={{
                    textOverflow: 'ellipsis',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <MdAttachFile style={{ fontSize: 24, marginRight: 10 }} />
                  {file.name}
                </Link>
                {!preview && (
                  <Tooltip label="Usuń załącznik">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        onRemoveFile(file.id);
                      }}
                    >
                      <MdDelete />
                    </Button>
                  </Tooltip>
                )}
              </Box>
            </ListItem>
          ))}
        </List>
      ) : null}
    </>
  );
};

Files.propTypes = {
  files: PropTypes.array.isRequired,
  onRemoveFile: PropTypes.func,
};

export default Files;
