import { baseApi } from '@/lib';

import {
  PaidSettlementsSummaryRequestDTO,
  PaidSettlementsSummaryResponseDTO,
  TodaySummaryResponseDTO,
} from '../types';

const enchancedApi = baseApi.enhanceEndpoints({
  addTagTypes: [],
});

export const reportsApi = enchancedApi.injectEndpoints({
  endpoints: (builder) => ({
    summary: builder.query<TodaySummaryResponseDTO, void>({
      query: () => 'summary/todaySummary',
    }),
    paidSettlementsSummary: builder.query<
      PaidSettlementsSummaryResponseDTO,
      PaidSettlementsSummaryRequestDTO
    >({
      query: ({ month }) => `summary/paidSettlementsSummary/${month}`,
    }),
  }),
});

export const { useSummaryQuery, usePaidSettlementsSummaryQuery } = reportsApi;
