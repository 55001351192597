import { Flex, HStack, Stack, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Paper from '@/components/ui/Paper';

import { ReactComponent as TeamIlustration } from '../assets/maintanance.svg';
import LoginForm from '../components/LoginForm';
import { useAuth } from '../hooks/useAuth';

const LoginView = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      navigate('/app');
    }
  }, [isAuth, navigate]);

  return (
    <Stack width={'full'} justify="center" align={'center'}>
      <Paper w={'60%'} maxW={'900px'} p={8} color={'black'}>
        <Stack spacing={1} mb={4} align={'center'}>
          <Text fontSize={'lg'} fontWeight={'extrabold'}>
            Logowanie
          </Text>
          <Text fontSize={'xl'}>4P Management</Text>
        </Stack>
        <HStack spacing={8}>
          <Flex w={'50%'}>
            <TeamIlustration width={'100%'} height={'250'} />
          </Flex>
          <Stack w={'50%'}>
            <LoginForm />
          </Stack>
        </HStack>
      </Paper>
    </Stack>
  );
};

export default LoginView;
