import { baseApi } from '@/lib';

import { LoginResponse, MeResponse } from '../types';

type LoginRequestDTO = {
  email: string;
  password: string;
};

const enchanced = baseApi.enhanceEndpoints({
  addTagTypes: ['Me'],
});

export const authApi = enchanced.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponse, LoginRequestDTO>({
      query: (data) => ({
        url: 'auth/login',
        method: 'POST',
        body: data,
      }),
    }),
    me: builder.query<MeResponse, void>({
      query: () => ({
        url: 'users/me',
      }),
    }),
  }),
});

export const { useLoginMutation, useMeQuery } = authApi;
