import { Box } from '@chakra-ui/react';
import React from 'react';

const UploadProgress = ({ files }: { files: Array<{ name: string; progress: string }> }) => {
  return (
    <Box style={{ fontSize: 11, marginLeft: 5, marginBottom: 16 }}>
      <Box style={{ fontWeight: 500 }}>Trwa wysyłanie plików</Box>
      {files.map((file) => {
        return <Box key={file.name}>{file.name}</Box>;
      })}
    </Box>
  );
};

export default UploadProgress;
