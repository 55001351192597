import { Flex, HStack, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';

import { Header } from '@/components/ui/Header/Header';
import { Breadcrumbs } from '@/components/ui/Navigation/Breadcrumbs';
import { SearchInput } from '@/components/ui/SearchInput/SearchInput';
import { Breadcrumb } from '@/types/Breadcrumbs';

import { ReportsList } from '../components/ReportsList';

const breadcrumbs: Breadcrumb[] = [
  {
    path: '/app/reports',
    displayValue: 'Raporty',
    isCurrentRoute: true,
  },
];

export const ReportsListPage = () => {
  const [search, setSearch] = useState('');

  return (
    <Stack>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <HStack pb={4}>
        <Header>Raporty</Header>
        <Flex flexGrow={1} />
        <SearchInput value={search} onChange={(e) => setSearch(e.target.value)} />
      </HStack>
      <ReportsList searchValue={search} />
    </Stack>
  );
};
