import { Box, HStack, Square, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { NavLink, useMatch } from 'react-router-dom';

type Props = {
  to: string;
  label: string;
  icon?: ReactElement;
  matchString?: string;
};

const DrawerMenuItem = ({ to, label, icon, matchString }: Props) => {
  const isActive = useMatch(matchString || `${to}/*`);

  return (
    <Box
      width={'100%'}
      p={2}
      as={NavLink}
      to={to}
      borderRadius={'md'}
      sx={isActive ? { background: 'whiteAlpha.200' } : {}}
    >
      <HStack w={'100%'} align={'center'} spacing={2}>
        {icon ? (
          <Square
            p={2}
            background={isActive ? 'whiteAlpha.900' : 'whiteAlpha.100'}
            color={isActive ? 'purple.800' : 'whiteAlpha.900'}
            borderRadius={'md'}
          >
            {icon}
          </Square>
        ) : null}
        <Text>{label}</Text>
      </HStack>
    </Box>
  );
};

export default DrawerMenuItem;
