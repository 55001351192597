import { useDisclosure } from '@chakra-ui/react';
import React from 'react';

import { useReportsQuery } from '../api/reportsApi';
import { ReportProps } from '../types';

import { ReportsListItem } from './ReportsListItem';
import { ReportsListItemSkeleton } from './ReportsListItemSkeleton';

export const Report = (props: ReportProps) => {
  const { reportType, ReportForm, filterString = '' } = props;

  const disclosure = useDisclosure();

  const { report, isFetching: isFetchingReports } = useReportsQuery(undefined, {
    selectFromResult: (res) => ({
      ...res,
      report: res.data?.find((rep) => rep.reportType === reportType),
    }),
  });

  if (isFetchingReports) return <ReportsListItemSkeleton />;

  if (
    !report ||
    (!report.reportName.toLocaleLowerCase().includes(filterString.toLocaleLowerCase()) &&
      !report.reportFormat.toLocaleLowerCase().includes(filterString.toLocaleLowerCase()))
  )
    return null;

  return (
    <>
      <ReportsListItem report={report} onClick={disclosure.onOpen} isLoading={isFetchingReports} />
      {disclosure.isOpen ? (
        <ReportForm isOpen={disclosure.isOpen} onClose={disclosure.onClose} report={report} />
      ) : null}
    </>
  );
};
