import React, { forwardRef } from 'react';
import ReactDatePicker from 'react-datepicker';

import FormInput from './FormInput';

type Props = ReactDatePicker['props'];

const DatePickerInput = forwardRef<HTMLInputElement, React.ComponentProps<typeof FormInput>>(
  (props, ref) => <FormInput ref={ref} {...props} />
);
DatePickerInput.displayName = 'DatePickerInput';

export const DatePicker = ({ ...props }: Props) => {
  return (
    <ReactDatePicker
      {...props}
      locale={'pl-PL'}
      customInput={<DatePickerInput name={`${name}-DatePicker`} />}
    />
  );
};
