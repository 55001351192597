import { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '@/hooks/store';
import { baseApi } from '@/lib';

import { logout } from '../stores/authSlice';

export const useAuth = () => {
  const { isAuth, token } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    localStorage.removeItem('access_token');
    dispatch(baseApi.util.resetApiState());
    dispatch(logout());
  }, [dispatch]);

  return { isAuth, logout: handleLogout, token };
};
