import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FormDatePicker } from '@/components/form/FormDatePicker';
import { FormReactSelect } from '@/components/form/FormReactSelect';
import { FormSection } from '@/components/form/FormSection';
import { Domain, useDomainsQuery } from '@/features/domains';

import { ReportFormProps } from '../../types';
import { downloadReport } from '../../utils/downloadReport';
import { handleDownloadReportError } from '../../utils/handleDownloadReportError';
import { ReportModalForm } from '../ReportModalForm';

type Form = {
  month: Date | null;
  domain: Domain | null;
};

const INITIAL_VALUES: Form = {
  month: new Date(),
  domain: null,
};

const schema = yup.object({
  month: yup.date().required('Pole wymagane'),
  domain: yup.object().nullable().required('Pole wymagane'),
});

export const ChildrenAttendanceInDaysByGroupForm = (props: ReportFormProps) => {
  const { report } = props;
  const { data: domains, isLoading: isLoadingDomains } = useDomainsQuery();

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Form>({
    defaultValues: INITIAL_VALUES,
    resolver: yupResolver(schema),
  });

  const handleFormSubmit = async (values: Form) => {
    try {
      await downloadReport(report, {
        month: dayjs(values.month).format('YYYY-MM'),
        domain: values.domain?.domain,
      });
      props.onClose();
    } catch (err: any) {
      handleDownloadReportError(err);
    }
  };

  return (
    <ReportModalForm
      {...props}
      onSubmit={handleSubmit(handleFormSubmit)}
      isSubmitting={isSubmitting}
    >
      <FormSection title={'Placówka'}>
        <FormReactSelect
          options={domains || []}
          control={control}
          name={'domain'}
          getOptionLabel={(o) => o.name}
          getOptionValue={(o) => o.domain}
          placeholder={'Wybierz placówki'}
          isLoading={isLoadingDomains}
        />
      </FormSection>
      <FormSection title={'Miesiąc'}>
        <FormDatePicker
          control={control}
          name={'month'}
          placeholderText={'Wybierz miesiąc'}
          showMonthYearPicker
          dateFormat={'MM-yyyy'}
        />
      </FormSection>
    </ReportModalForm>
  );
};
