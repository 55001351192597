import { Box } from '@chakra-ui/react';

import { useGetBoardPostsQuery } from '../api/boardPostsApi';
import BoardPostList from '../components/BoardPostList';

const BoardPostsPage = () => {
  const { data } = useGetBoardPostsQuery();
  return (
    <Box>
      <BoardPostList data={data} />
    </Box>
  );
};

export default BoardPostsPage;
