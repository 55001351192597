import { baseApi } from '@/lib';

import { DomainResponseDto } from '../types/Domain';

const enchancedApi = baseApi.enhanceEndpoints({
  addTagTypes: ['Domains'],
});

export const domainsApi = enchancedApi.injectEndpoints({
  endpoints: (builder) => ({
    domains: builder.query<DomainResponseDto, void>({
      query: () => 'domains',
    }),
  }),
});

export const { useDomainsQuery } = domainsApi;
