import { Center } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { authRoutes } from '@/features/auth';

const PublicWrapper = () => {
  return (
    <Center minH={'100vh'}>
      <Outlet />
    </Center>
  );
};

export const publicRoutes = [
  {
    path: '/',
    element: <PublicWrapper />,
    children: authRoutes,
  },
];
